import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCode } from "react-qrcode-logo";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCopy } from 'react-icons/fa';
import napas247Icon from "../images/napas247.png";
import vietQRIcon from "../images/vietqr.png";
import { QRPay } from 'vietnam-qr-pay';
import CryptoJS from 'crypto-js';
const SECRET_KEY = '8eb11eb80894c27fafe4228dbe15ab4d';

const PaymentLinkPage: React.FC = () => {
  const [paymentData, setPaymentData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [purpose, setPurpose] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get('transactionId');

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const payload = { transactionId: transactionId };
      const validateString = CryptoJS.MD5(JSON.stringify(payload) + SECRET_KEY).toString();
      const response = await axios.post('https://sbx-apix.bitcake.vn/payment/detail-query', payload, {
        headers: {
          'x-api-client': 'BITCAKE_WEB',
          'lang': 'vi',
          'x-api-validate': validateString,
          'Content-Type': 'application/json',
          'X-API-Key': 'D8Fw3Cwwa6Owlmfr05Twju3H057n6TQ66GxKrzMITIp73bN3DyDeJHbNdOCpDLYZe48ISjrfS1nRpq46KQB9Yp4WonlMXJdPdnOuOfsBKHdcVlOt9JLkOmpwx6vXTLuu'
        }
      });
      if (response.data.code === 143000) {
        setPaymentData(response.data.data);
        const qrPayShop = new QRPay(response.data.data.payData.qrCode);
        if (qrPayShop.isValid) {
          setPurpose(qrPayShop.additionalData.purpose ?? '');
        }
        setError(null); // Clear any previous errors
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('Error fetching payment data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transactionId !== null && transactionId !== '') {
      fetchPaymentData();
    }
  }, [transactionId]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard',{position: "top-center",className: 'text-center'});
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
    document.execCommand(
        "copy",
        true,
        text
      );
  };

  if (transactionId === null || transactionId === '') {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-red-500">Yêu cầu tải thông tin thanh toán không hợp lệ!</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-gray-700">Loading...</div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }
  
  return (
    <div className="flex justify-center items-center min-h-screen bg-white">
      <div className="bg-gray-100 shadow-md rounded-lg p-6 max-w-lg m-3">
        <h1 className="text-lg font-bold text-center mb-4">Thanh toán đơn hàng</h1>
  
        <p className="text-xs text-gray-400 text-center mb-4">Mã đơn hàng: {paymentData.transactionId}</p>
        <p className="text-base text-blue-700 font-semibold text-center mb-4">Số tiền thanh toán: {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(paymentData.amount)}</p>
        {paymentData.state === 'SUCCEEDED' ? (
          <div className="bg-green-600 shadow-md rounded-lg p-2 max-w-lg">
            <p className="text-white text-center m-2">Đơn hàng đã thanh toán thành công</p>
          </div>
        ) : (
          <div>
            <p className="text-red-950 text-center mb-4">Vui lòng quét mã QR để thanh toán, hoặc chuyển khoản theo nội dung bên dưới</p>
  
            {/* QR Code */}
            <div className="flex justify-center mb-6">
              <QRCode
                value={paymentData.payData.qrCode}
                size={220}
                qrStyle={"squares"}
              />
            </div>
            <div className="flex justify-between space-x-3 mx-3">
              <div className="flex w-1/2 justify-center m-2">
                <img src={napas247Icon} />
              </div>
              <div className="flex w-1/2 justify-center m-2">
                <img src={vietQRIcon} />
              </div>
            </div>
            <hr className="my-4 border-gray-300" />
            {/* Bank Details */}
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="font-bold">Ngân Hàng :</span>
                <span>{paymentData.payData.bankName}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-bold">Tên tài khoản :</span>
                <span>{paymentData.payData.accountName}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-bold">Số tài khoản :</span>
                <div className="flex items-center">
                  <span>{paymentData.payData.accountNumber}</span>
                  <button onClick={() => copyToClipboard(paymentData.payData.accountNumber)} className="ml-2 text-blue-500 flex items-center">
                    <FaCopy className="mr-1" />
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-bold">Nội dung chuyển khoản:</span>
                <div className="flex items-center">
                  <span>{purpose}</span>
                  <button onClick={() => copyToClipboard(purpose ?? '')} className="ml-2 text-blue-500 flex items-center">
                    <FaCopy className="mr-1" />
                  </button>
                </div>
              </div>
            </div>
            <ul className="mt-6 text-xs text-gray-700 space-y-2 text-left">
              <li>1. Sử dụng ứng dụng ngân hàng của bạn để quét mã QR để hoàn tất chuyển khoản nhanh chóng!!!</li>
              <li>2. Vui lòng nhất định phải điền Nội Dung CK <span className="text-red-500">{purpose}</span> phía trên khi chuyển khoản.</li>
              <li>3. Nếu không điền đủ thông tin nội dung chuyển khoản, giao dịch của bạn sẽ phải chờ để được xử lý.</li>
            </ul>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentLinkPage;